<template>
  <div>
    <progress-bouncing v-if="isLoading"/>
    <v-sheet v-else-if="!!basicImport" class="text-left">
      <div class="d-flex" v-if="!isParsing">
        <div class="font-weight-bold my-5 mr-5 align-self-center">{{ products.length }} products</div>
        <div class="my-5">
          <template v-for="status in productStatuses" >
            <v-chip v-if="getProductCountByStatusId(status.id)"
                    :key="status.id"
                    :outlined="selectedStatusFilter !== status.id"
                    @click="filterProductByStatus(status.id)"
                    class="mx-1" :color="status.color">
            {{ status.text }}: {{ getProductCountByStatusId(status.id) }}
            </v-chip>
          </template>
        </div>
      </div>
      <v-data-table :items="products.filter(x => x.status === selectedStatusFilter || !selectedStatusFilter)" :headers="headers"
                    :loading="isParsing"
                    mobile-breakpoint="0">
        <template v-slot:item="prop">
          <tr>
            <td class="text-center sticky--left">
              <v-tooltip bottom max-width="300" :disabled="!prop.item.errorOrWarning.value">
                <template v-slot:activator="{on}">
                  <v-btn v-on="on" :color="prop.item.errorOrWarning.color">
                    <v-icon>{{ prop.item.errorOrWarning.icon }}</v-icon>
                  </v-btn>
                </template>
                <div class="text-left">
                  <div v-for="(error, i) in prop.item.errorList" :key="i">
                    <div class="py-2">{{ i + 1 }}. {{ error.exceptionString }}</div>
                  </div>
                  <div v-if="prop.item.errorOrWarning.value === 'warning'">
                    This product is already imported before and it will be left out in import process because option
                    "Disable updating existing products" is activated
                  </div>
                </div>
              </v-tooltip>
            </td>
            <td v-for="(header, i) in headers.filter((x,j) => j > 0)" :key="i" :set="error = errorByColumn(prop.item.errorList, header.value)">
              <span v-if="!error && header.format === 'date' && prop.item[header.value]">
                
                {{ moment(prop.item[header.value]).format('DD.MM.YYYY') }}
              </span>
              <span v-else>
                {{ !!error ? error.inFileValue : prop.item[header.value] }}
              </span>
              <v-tooltip bottom v-if="error" max-width="300">
                <template v-slot:activator="{on}">
                  <v-btn icon>
                    <v-icon v-on="on" color="error">mdi-alert</v-icon>
                  </v-btn>
                </template>
                <div>
                  <div v-if="error">
                    <div>{{ error.exceptionString }}</div>
                    <!--<div v-if="error.inFileValue">Value set in file: {{ error.inFileValue }}</div>-->
                  </div>
                </div>
              </v-tooltip>
            </td>
          </tr>
        </template>

      </v-data-table>
    </v-sheet>
  </div>
</template>

<script>
import {waitFor} from "vue-wait/src/helpers";
import wizardMixin from "./wizard.mixin";

export default {
  name: "Preview",
  mixins: [wizardMixin],
  props: {
    isLoading: Boolean
  },
  data() {
    return {
      initHeaders: [
        {text: 'Status', value: 'status', format: 'string'},
        {text: 'ProductId', value: 'productId', format: 'string'},
        {text: 'SupplementaryNumber', value: 'supplementaryNumber', format: 'string'},
        {text: 'ProductLongName', value: 'productLongName', format: 'string'},
        {text: 'ProductShortName', value: 'productShortName', format: 'string'},
        {text: 'BrandId', value: 'brandId', format: 'string'},
        {text: 'BrandName', value: 'brandName', format: 'string'},
        {text: 'SerieId', value: 'serieId', format: 'string'},
        {text: 'SerieName', value: 'serieName', format: 'string'},
        {text: 'Description', value: 'description', format: 'string'},
        {text: 'ShelfDescription', value: 'shelfDescription', format: 'string'},
        {text: 'Ean', value: 'ean', format: 'int'},
        {text: 'ArticleType', value: 'ArticleType', format: 'string'},
        {text: 'ActiveStatus', value: 'activeStatus', format: 'bool'},
        {text: 'ProductType', value: 'productType', format: 'string'},
        {text: 'CategoryName', value: 'categoryName', format: 'string'},
        {text: 'InPriceExVat', value: 'inPriceExVat', format: 'int'},
        {text: 'RecommendedSalesPriceIncVat', value: 'recommendedSalesPriceIncVat', format: 'int'},
        {text: 'Vat', value: 'vat', format: 'int'},
        {text: 'Size', value: 'size', format: 'string'},
        {text: 'Unit', value: 'unit', format: 'string'},
        {text: 'PackageSize', value: 'packageSize', format: 'int'},
        {text: 'MinimumOrderQuantity', value: 'minimumOrderQuantity', format: 'int'},
        {text: 'ImagesFileNames', value: 'imagesFileNames', format: 'string'},
        {text: 'OrderAvailableFrom', value: 'orderAvailableFrom', format: 'date'},
        {text: 'OrderAvailableTo', value: 'orderAvailableTo', format: 'date'}
      ],
      products: [],
      selectedStatusFilter: null,
      productStatuses: [{id: 1, color: 'success', text: 'Success'}, {id: 2, color: 'error', text: 'Error'}, {id: 3, color: 'warning', text: 'Warning'}]
    }
  },
  computed: {
    isParsing() {
      return this.$wait.is('loading basic-import-wizard preview')
    },
    getProductCountByStatusId() {
      return id => this.products.filter(x => x.status === id).length
    },
    errorByColumn() {
      return (errorList, header) => errorList?.find(x => x.fixitColumnName.toLowerCase() === header.toLowerCase())
    },
    headers() {
      return this.$wait.is('loading basic-import-wizard preview') ? [] : this.initHeaders
    },
    fixitColumns() {
      return this.$store.getters['basic-import/get']('fixitColumns')
    },
  },
  methods: {
    filterProductByStatus(status) {
      this.selectedStatusFilter = this.selectedStatusFilter === status ? null : status
    },
    getErrorOrWarning(productData) {
      let returnObj = {value: null, color: 'success', icon: 'mdi-check'}
      productData.status = 1
      if (productData.errorList.length) {
        productData.status = 2
        Object.assign(returnObj, {value: 'error', color: 'error', icon: 'mdi-alert-circle-outline'})
      }
      else if (this.basicImport.excludeExistingProducts && productData.productExistsInOpp) {
        productData.status = 3
        Object.assign(returnObj, {value: 'warning', color: 'warning', icon: 'mdi-alert'})
      }
      productData.errorOrWarning = returnObj
    },
    async formSubmit() {
      return await this.$store.dispatch('basic-import/runImport', {
        importId: this.basicImport.id
      })
    }
  },
  created() {
    if (!this.basicImport)
      this.getActiveImport()
  },
  watch: {
    basicImport: {
      handler(val) {
        if (val) {
          waitFor('loading basic-import-wizard preview', async () => {
            let products = await this.$store.dispatch('basic-import/parseAndValidateImport')
            products.forEach(x => this.getErrorOrWarning(x))
            this.products = products
          }).apply(this)
        }
      }, immediate: true
    }
  }
}
</script>

<style scoped lang="scss">
.sticky--left {
  z-index: 2 !important;
  background-color: white;
  position: sticky;
  left: 0;
}

.product-error-bg {
  background-color: #E39090;
}

.product-success-bg {
  background-color: #83DB83F9;
}

.product-warn-bg {
  background-color: #E3BC90FF;
}

.v-data-table {
  color: var(--v-primary-base);

  ::v-deep td {
    white-space: nowrap;
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
    border-right: thin solid rgba(0, 0, 0, 0.12);
  }

  ::v-deep th {
    border-right: thin solid rgba(0, 0, 0, 0.12);
    &:first-of-type {
      text-align: center !important;
      z-index: 2 !important;
      position: sticky;
      left: 0;
    }

    white-space: nowrap;
    color: inherit !important;
    background-color: #EFDDD1;
    z-index: 1;
  }
}
</style>
